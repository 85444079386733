import React, { useState, useEffect, useRef } from "react";
import { NumericFormat } from "react-number-format";
import {
  Button,
  Table,
  Navbar,
  Container,
  Card,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import Dinero from "dinero.js";

import { DataInput } from "./components/DataInput.js";
import { Integration, INTEGRATIONS } from "./components/Integration.js";
import { Projections } from "./components/Projections.js";
import { Cost } from "./components/Cost.js";

import { defaultState, getURLState, setURLState } from "./state.js";
import Icon from "./icons";

const urlState = getURLState();

const logoUrl = new URL("logo.svg", import.meta.url);

function dollarsToCents(dollars) {
  return parseInt((dollars * 100).toFixed(0));
}

function App() {
  const initial = urlState ? urlState : defaultState;

  const [monthlyUsers, setMonthlyUsers] = useState(initial.monthlyUsers);
  const [aov, setAOV] = useState(initial.aov);
  const [conversionRate, setConversionRate] = useState(initial.conversionRate);
  const [ebitdaMargin, setEbitdaMargin] = useState(initial.ebitdaMargin);
  const [stage, setStage] = useState(initial.stage);
  const [integration, setIntegration] = useState(initial.integration);
  const [purchasePrice, setPurchasePrice] = useState(initial.purchasePrice);
  const [annualBudget, setAnnualBudget] = useState(initial.annualBudget);
  const [manualConversionRate, setManualConversionRate] = useState(
    initial.manualConversionRate,
  );
  const [manualAOV, setManualAOV] = useState(initial.manualAOV);

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setURLState({
        monthlyUsers,
        aov,
        conversionRate,
        ebitdaMargin,
        stage,
        integration,
        purchasePrice,
        annualBudget,
        manualConversionRate,
        manualAOV,
      });
    }
  }, [
    monthlyUsers,
    aov,
    conversionRate,
    ebitdaMargin,
    stage,
    integration,
    purchasePrice,
    annualBudget,
    manualConversionRate,
    manualAOV,
  ]);

  const isReady =
    monthlyUsers !== undefined &&
    aov !== undefined &&
    conversionRate !== undefined &&
    ebitdaMargin !== undefined;

  const annualUsers = monthlyUsers * 12;
  const annualRevenue = isReady
    ? aov.multiply(annualUsers * conversionRate)
    : undefined;
  const annualEbitda = isReady
    ? annualRevenue.multiply(ebitdaMargin)
    : undefined;

  const usage = Object.entries(INTEGRATIONS).reduce((acc, [k, { value }]) => {
    if (!integration[k]) {
      return acc;
    }
    return acc + value;
  }, 0);

  const cases = [
    {
      name: "Upside (+σ)",
      aov: 2.68,
      conversionRate: 1.15,
      variant: "success",
    },
    { name: "Base (x̄)", aov: 1.57, conversionRate: 0.712, variant: "warning" },
    {
      name: "Downside (-σ)",
      aov: 0.548,
      conversionRate: 0.462,
      variant: "danger",
    },
    {
      name: "Custom",
      isManual: true,
      aov: manualAOV,
      onAOV: (v) => setManualAOV(v !== undefined ? v / 100 : undefined),
      conversionRate: manualConversionRate,
      onConversionRate: (v) =>
        setManualConversionRate(v !== undefined ? v / 100 : undefined),
      variant: "light",
    },
  ].map((record) => {
    if (!isReady) {
      return record;
    }

    const standardRevenue = annualRevenue.multiply(1 - usage);
    const protexRevenue = aov
      .multiply(1 + (record.aov || 0))
      .multiply(
        annualUsers *
          usage *
          (conversionRate * (1 + (record.conversionRate || 0))),
      );
    const projectedRevenue = standardRevenue.add(protexRevenue);
    const increaseRevenue = projectedRevenue.subtract(annualRevenue);

    const projectedEbitda = projectedRevenue.multiply(ebitdaMargin);
    const increaseEbitda = projectedEbitda.subtract(annualEbitda);

    const payback = (
      (purchasePrice?.getAmount() || 0) / increaseEbitda.divide(365).getAmount()
    ).toFixed(0);
    const roi =
      increaseEbitda.getAmount() / (annualBudget?.getAmount() || 0).toFixed(1);

    return {
      ...record,
      annualRevenue: projectedRevenue,
      annualIncrease: increaseRevenue,
      annualEbitda: projectedEbitda,
      annualEbitdaIncrease: increaseEbitda,
      payback: payback,
      roi: roi,
    };
  });

  function Next() {
    const DISABLED = {
      1: !isReady,
      2: !usage,
      4: purchasePrice?.isZero() || annualBudget?.isZero(),
    };

    const LABELS = {
      1: "Metrics",
      2: "Integrations",
      3: "Projections",
      4: "Cost",
      5: "Return On Investment",
    };

    if (stage !== 0 && LABELS[stage + 1] === undefined) {
      return <></>;
    }

    return (
      <Card bg="light" className="w-full my-5 p-0">
        <Card.Body className="d-flex justify-content-between align-items-center p-2">
          <strong className="ps-2">
            {stage + 1}. {LABELS[stage + 1]}
          </strong>
          <Button
            disabled={DISABLED[stage]}
            variant="dark"
            onClick={() => setStage((v) => v + 1)}
          >
            {stage === 0 ? "Start" : "Continue"}
          </Button>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Navbar bg="dark">
        <Container>
          <Navbar.Brand className="mx-auto">
            <img height="50" src={logoUrl} />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container>
        {stage >= 1 ? (
          <DataInput
            monthlyUsers={monthlyUsers}
            setMonthlyUsers={setMonthlyUsers}
            aov={aov}
            setAOV={setAOV}
            conversionRate={conversionRate}
            setConversionRate={setConversionRate}
            ebitdaMargin={ebitdaMargin}
            setEbitdaMargin={setEbitdaMargin}
            annualRevenue={annualRevenue}
            annualEbitda={annualEbitda}
          />
        ) : undefined}

        {stage >= 2 ? (
          <Integration
            integration={integration}
            setIntegration={setIntegration}
            usage={usage}
          />
        ) : undefined}

        {stage >= 3 ? (
          <Projections cases={cases} usage={usage} stage={stage} />
        ) : undefined}

        {stage >= 4 ? (
          <Cost
            purchasePrice={purchasePrice}
            setPurchasePrice={setPurchasePrice}
            annualBudget={annualBudget}
            setAnnualBudget={setAnnualBudget}
          />
        ) : undefined}

        {stage === 5 ? <div className="pb-5" /> : undefined}

        <Next />
      </Container>
    </>
  );
}

export default App;
