import { Row, Col, ProgressBar, Form } from "react-bootstrap";

const INTEGRATIONS = {
  PROOF_OF_CONCEPT: {
    mask: 0b00000001,
    label: "Proof of Concept",
    value: 0.04,
  },
  STANDARD: { mask: 0b00000010, label: "Standard", value: 0.05 },
  FULL: { mask: 0b00000100, label: "Full", value: 0.06 },
  MAIN_NAVIGATION: { mask: 0b00001000, label: "Main Navigation", value: 0.05 },
  SOCIAL_MEDIA: {
    mask: 0b00100000,
    label: "Social Media Campaign",
    value: 0.05,
  },
  ADVERTISING: { mask: 0b01000000, label: "Paid Advertising", value: 0.05 },
  SALES_TRAINING: {
    mask: 0b00010000,
    label: "Internal Sales Training",
    value: 0.02,
  },
  VIRTUAL_CREATIVE: {
    mask: 0b10000000,
    label: "Virtual Creative",
    value: 0.02,
  },
};

const GROUPS = [
  {
    name: "Integration Level",
    items: ["PROOF_OF_CONCEPT", "STANDARD", "FULL"],
    type: "radio",
  },
  {
    name: "Boosts",
    items: [
      "MAIN_NAVIGATION",
      "SOCIAL_MEDIA",
      "ADVERTISING",
      "SALES_TRAINING",
      "VIRTUAL_CREATIVE",
    ],
    type: "checkbox",
  },
];

function Integration({ integration, setIntegration, usage }) {
  return (
    <div className="mt-5">
      <Row>
        {GROUPS.map(({ name, items, type }) => {
          return (
            <Col key={name} className="mb-2" sm={6}>
              <p className="mb-1 text-muted">
                <strong>{name}</strong>
              </p>
              {items.map((k) => (
                <Form.Check
                  inline
                  label={INTEGRATIONS[k].label}
                  checked={integration[k]}
                  type={type}
                  key={k}
                  id={k}
                  onChange={(e) => {
                    let tmp;
                    if (type === "radio") {
                      tmp = items.reduce((acc, record) => {
                        acc[record] = record === k ? e.target.checked : false;
                        return acc;
                      }, {});
                    } else {
                      tmp = { [k]: e.target.checked };
                    }
                    setIntegration({ ...integration, ...tmp });
                  }}
                />
              ))}
            </Col>
          );
        })}
      </Row>

      <ProgressBar
        now={usage * 100}
        className="mt-3 mb-1"
        style={{ height: "25px" }}
        label={`${(usage * 100).toFixed(0)}% Protex Conversions`}
      />
      <small className="text-muted">
        Drive more conversions through Protex to leverage higher conversion
        rates and average order values.
      </small>
    </div>
  );
}

export { Integration, INTEGRATIONS };
