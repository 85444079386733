import Dinero from "dinero.js";

import { INTEGRATIONS } from "./components/Integration.js";

function decodeIntegration(v) {
  const result = {};

  for (const [k, { mask }] of Object.entries(INTEGRATIONS)) {
    result[k] = !!(v & mask);
  }

  return result;
}

function encodeIntegration(v) {
  let result = 0;

  for (const [k, { mask }] of Object.entries(INTEGRATIONS)) {
    if (!v[k]) continue;
    result = result | mask;
  }

  return result;
}

export const defaultState = {
  stage: 0,
  integration: { STANDARD: true },
  manualConversionRate: 0.2,
  manualAOV: 0.2,
};

export function getURLState() {
  const params = new URLSearchParams(window.location.search);

  const monthlyUsers = params.has("monthlyUsers")
    ? parseInt(params.get("monthlyUsers"))
    : null;

  const aov = params.has("aov") ? parseInt(params.get("aov")) : null;

  const conversionRate = params.has("conversionRate")
    ? parseFloat(params.get("conversionRate"))
    : null;

  const ebitdaMargin = params.has("ebitdaMargin")
    ? parseFloat(params.get("ebitdaMargin"))
    : null;

  const stage = params.has("stage") ? parseInt(params.get("stage")) : null;

  const integration = params.has("integration")
    ? parseInt(params.get("integration"))
    : null;

  const purchasePrice = params.has("purchasePrice")
    ? parseInt(params.get("purchasePrice"))
    : null;

  const annualBudget = params.has("annualBudget")
    ? parseInt(params.get("annualBudget"))
    : null;

  const manualConversionRate = params.has("manualConversionRate")
    ? parseFloat(params.get("manualConversionRate"))
    : null;

  const manualAOV = params.has("manualAOV")
    ? parseFloat(params.get("manualAOV"))
    : null;

  if (
    monthlyUsers === null ||
    isNaN(monthlyUsers) ||
    aov === null ||
    isNaN(aov) ||
    conversionRate === null ||
    isNaN(conversionRate) ||
    ebitdaMargin === null ||
    isNaN(ebitdaMargin) ||
    stage === null ||
    isNaN(stage) ||
    integration === null ||
    isNaN(integration) ||
    purchasePrice === null ||
    isNaN(purchasePrice) ||
    annualBudget === null ||
    isNaN(annualBudget) ||
    manualConversionRate === null ||
    isNaN(manualConversionRate) ||
    manualAOV === null ||
    isNaN(manualAOV)
  ) {
    return;
  }

  return {
    monthlyUsers,
    aov: new Dinero({ amount: aov }),
    conversionRate,
    ebitdaMargin,
    stage,
    integration: decodeIntegration(integration),
    purchasePrice: new Dinero({ amount: purchasePrice }),
    annualBudget: new Dinero({ amount: annualBudget }),
    manualConversionRate,
    manualAOV,
  };
}

export function setURLState(state) {
  const params = new URLSearchParams();

  if (
    state.monthlyUsers === undefined ||
    state.aov === undefined ||
    state.conversionRate === undefined ||
    state.ebitdaMargin === undefined ||
    state.stage === undefined ||
    state.integration === undefined ||
    state.purchasePrice === undefined ||
    state.annualBudget === undefined ||
    state.manualConversionRate === undefined ||
    state.manualAOV === undefined
  ) {
    return;
  }

  params.set("monthlyUsers", state.monthlyUsers);
  params.set("aov", state.aov.getAmount());
  params.set("conversionRate", state.conversionRate);
  params.set("ebitdaMargin", state.ebitdaMargin);
  params.set("stage", state.stage);
  params.set("integration", encodeIntegration(state.integration));
  params.set("purchasePrice", state.purchasePrice.getAmount());
  params.set("annualBudget", state.annualBudget.getAmount());
  params.set("manualConversionRate", state.manualConversionRate);
  params.set("manualAOV", state.manualAOV);

  window.history.replaceState({}, "", `${location.pathname}?${params}`);
}
