import { NumericFormat } from "react-number-format";
import { Row, Col, Form } from "react-bootstrap";
import Dinero from "dinero.js";

function dollarsToCents(dollars) {
  return parseInt((dollars * 100).toFixed(0));
}

function DataInput({
  monthlyUsers,
  setMonthlyUsers,
  aov,
  setAOV,
  conversionRate,
  setConversionRate,
  ebitdaMargin,
  setEbitdaMargin,
  annualRevenue,
  annualEbitda,
}) {
  return (
    <>
      <Row>
        <Col xs="6" md="3">
          <Form.Group>
            <Form.Label>Monthly Visits</Form.Label>
            <NumericFormat
              type="tel"
              value={monthlyUsers}
              onValueChange={({ floatValue }) => setMonthlyUsers(floatValue)}
              decimalScale={0}
              thousandSeparator={true}
              allowNegative={false}
              className="form-control"
              placeholder="50,000 to 5,000,000"
            />
          </Form.Group>
        </Col>
        <Col xs="6" md="3">
          <Form.Group>
            <Form.Label>Average Order Value</Form.Label>
            <NumericFormat
              type="tel"
              value={aov !== undefined ? aov.getAmount() / 100 : ""}
              onValueChange={({ floatValue }) => {
                setAOV(
                  floatValue !== undefined
                    ? new Dinero({ amount: dollarsToCents(floatValue) })
                    : undefined,
                );
              }}
              decimalScale={2}
              thousandSeparator={true}
              prefix={"$"}
              allowNegative={false}
              className="form-control"
              placeholder="$250 to $1,500"
            />
          </Form.Group>
        </Col>
        <Col xs="6" md="3">
          <Form.Group>
            <Form.Label>Conversion Rate</Form.Label>
            <NumericFormat
              type="tel"
              value={conversionRate !== undefined ? conversionRate * 100 : ""}
              onValueChange={({ floatValue }) =>
                setConversionRate(
                  floatValue !== undefined ? floatValue / 100 : undefined,
                )
              }
              decimalScale={2}
              thousandSeparator={true}
              suffix={"%"}
              allowNegative={false}
              className="form-control"
              placeholder="0.5% to 2%"
            />
          </Form.Group>
        </Col>
        <Col xs="6" md="3">
          <Form.Group>
            <Form.Label>EBITDA Margin</Form.Label>
            <NumericFormat
              type="tel"
              value={ebitdaMargin !== undefined ? ebitdaMargin * 100 : ""}
              onValueChange={({ floatValue }) =>
                setEbitdaMargin(
                  floatValue !== undefined ? floatValue / 100 : undefined,
                )
              }
              decimalScale={2}
              thousandSeparator={true}
              suffix={"%"}
              allowNegative={false}
              className="form-control"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs="6" md="6">
          <Form.Group>
            <Form.Label>Annual Revenue</Form.Label>
            <NumericFormat
              readOnly
              disabled
              value={
                annualRevenue !== undefined
                  ? annualRevenue.getAmount() / 100
                  : ""
              }
              decimalScale={2}
              thousandSeparator={true}
              prefix={"$"}
              allowNegative={false}
              className="form-control"
            />
          </Form.Group>
        </Col>
        <Col xs="6" md="6">
          <Form.Group>
            <Form.Label>Annual EBITDA</Form.Label>
            <NumericFormat
              readOnly
              disabled
              value={
                annualEbitda !== undefined ? annualEbitda.getAmount() / 100 : ""
              }
              decimalScale={2}
              thousandSeparator={true}
              prefix={"$"}
              allowNegative={false}
              className="form-control"
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}

export { DataInput };
