import { NumericFormat } from "react-number-format";
import { Card, Row, Col, Form } from "react-bootstrap";
import Dinero from "dinero.js";

function dollarsToCents(dollars) {
  return parseInt((dollars * 100).toFixed(0));
}

function Cost({
  purchasePrice,
  setPurchasePrice,
  annualBudget,
  setAnnualBudget,
}) {
  return (
    <>
      <Row className="mt-5 md-gap-5">
        <Col>
          <Form.Group>
            <Form.Label>Purchase Price</Form.Label>
            <NumericFormat
              type="tel"
              value={
                purchasePrice !== undefined
                  ? purchasePrice.getAmount() / 100
                  : ""
              }
              onValueChange={({ floatValue }) =>
                setPurchasePrice(
                  floatValue !== undefined
                    ? new Dinero({ amount: dollarsToCents(floatValue) })
                    : undefined,
                )
              }
              decimalScale={2}
              thousandSeparator={true}
              prefix={"$"}
              allowNegative={false}
              className="form-control"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Annual Budget</Form.Label>
            <NumericFormat
              type="tel"
              value={
                annualBudget !== undefined ? annualBudget.getAmount() / 100 : ""
              }
              onValueChange={({ floatValue }) =>
                setAnnualBudget(
                  floatValue !== undefined
                    ? new Dinero({ amount: dollarsToCents(floatValue) })
                    : undefined,
                )
              }
              decimalScale={2}
              thousandSeparator={true}
              prefix={"$"}
              allowNegative={false}
              className="form-control"
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}

export { Cost };
